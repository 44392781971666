import React from "react";
import styled from "styled-components";
import Link from "next/link";
import Button from '@material-ui/core/Button';

const Container = styled.div`
  width: 100%;

  @media (max-width: 800px) {
    display: none;
  }
`;

const StyledButton = styled(Button)`
  margin-left: 10px;
  font-weight: 600;
  font-size: 14px;
`

const SiteMenu = () => {
  return (
    <Container>
      {/* <Link href="/whitelist" passHref>
        <StyledButton>
          Whitelist
        </StyledButton>
      </Link> */}
      {/* <Link href="/loja" passHref>
        <StyledButton>
          Loja
        </StyledButton>
      </Link> */}
      <Link href="https://rprp.city/discord" passHref>
        <StyledButton>
          Discord
        </StyledButton>
      </Link>
      {/* <Link href="/codigo-conduta" passHref>
        <StyledButton>
          Código de Conduta
        </StyledButton>
      </Link> */}
      {/* <Link href="https://rprp.tv" passHref>
        <StyledButton>
          RPRP TV
        </StyledButton>
      </Link> */}
    </Container>
  )
}

export default SiteMenu;
