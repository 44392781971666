import React from "react";
import useUser from "hooks/useUser";
import { useRouter } from 'next/router';
import styled from "styled-components";
import AdminMenu from "./AdminMenu";
import Header from "./Header";

const Container = styled.div`
  height: calc(100% - 66px);
  display: flex;
`

const Content = styled.div`
  flex: 1;
  height: 100%;
  overflow-x: hidden;
`

const Layout: React.FC = ({ children }) => {
  const user = useUser();
  const router = useRouter();

  return (
    <>
      <Header />
      <Container>
        {(router.pathname.startsWith('/admin') && user?.isAdmin) && <AdminMenu />}
        <Content id="scroll-container">
          {children}
        </Content>
      </Container>
    </>
  )
}

export default Layout;
