import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#4977DB',
    },
    secondary: {
      main: '#799AD8',
    },
    background: {
      paper: '#111',
      default: '#000',
    }
  },
  typography: {
    fontFamily: 'Titillium Web, sans-serif',
    fontSize: 16
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        backgroundColor: '#000',
        fontSize: '16px'
      },
      arrow: {
        "&::before": {
          backgroundColor: "#000",
        }
      },
    },
    MuiBackdrop: {
      root: {
        zIndex: 1,
      },
    },
    MuiTableCell: {
      head: {
        borderColor: "#222",
      },
      root: {
        borderBottom: "1px solid #222",
      }
    },
  },
});

export default theme;
