import React from "react";
import Link from "next/link";
import styled from "styled-components";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from "@material-ui/core/IconButton";
import Logo from "./Logo";
import UserMenu from "./UserMenu";
import SiteMenu from "./SiteMenu";
import SocialMenu from "./SocialMenu";
import MobileMenu from "./MobileMenu";

const StyledAppBar = styled(AppBar)`
  box-shadow: none;
  background: #111;
  border-bottom: 1px solid #000;
`

const StyledToolbar = styled(Toolbar)`
  height: 65px;
`

const MobileMenuButton = styled(IconButton)`
  margin-right: 0;

  @media (min-width: 800px) {
    display: none;
  }
`

const LogoContainer = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  margin-right: 40px;
`

const Header = () => {
  const [mobileOpen, setMobileOpen] = React.useState<boolean>(false);

  return (
    <>
      <MobileMenu open={mobileOpen} onClose={() => setMobileOpen(false)} />
      <StyledAppBar position="static">
        <StyledToolbar>
          <Box display="flex" alignItems="center" flex="1">
            <MobileMenuButton edge="start" aria-label="menu" onClick={() => setMobileOpen(true)}>
              <MenuIcon />
            </MobileMenuButton>
            <Link href="/" passHref>
              <LogoContainer>
                <Logo />
              </LogoContainer>
            </Link>
            <SiteMenu />
          </Box>
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <SocialMenu />
            <UserMenu />
          </Box>
        </StyledToolbar>
      </StyledAppBar>
    </>
  )
}

export default Header;
